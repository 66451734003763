<template>
<div>
  <Loading :isLoading="isLoading" />
  <div>
    <p style="padding:10px 10px 0px 10px;font-size:20px;color:#810042;font-weight:700;font-family:Lato;">Solicitudes Incompletas</p>
  </div>
  <div class="py-2">
    <TableComponent :key="render" :columns="columns" :rows="rows" :rangeInput="true" :exportInput="true" />
  </div>
</div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from "vue";
import Loading from '@/components/Loading/VueLoading.vue';
import '@/assets/css/tailwind.css';
import TableComponent from "@/components/Forms/Tables/TableComponent";
import AdmissionRequest from "@/classes/AdmissionRequest.js";

//Iconos
import checkIcon from '@/assets/yes.svg'
import crossIcon from '@/assets/no.svg'

export default {
  name: 'SolicitudesAceptadas',

  setup() {
    let app = getCurrentInstance();
    const isLoading = ref(true);
    const apiToken = ref("");
    const admissionRequestObject = ref(null);
    
    let admissionRequestList = ref([]);
    let columns = ref([]);
    let rows = ref([]);
    let fields = ref([]);
    let render = ref(false);
    
    onMounted(async() => {
       app = app.appContext.config.globalProperties;
       apiToken.value = sessionStorage.getItem("login");
       admissionRequestObject.value = new AdmissionRequest();
       await getUnfinishedRequest(apiToken.value);
       getColumns();
       getRows();
       isLoading.value = false;
       render.value = true;
    });
    
    const getUnfinishedRequest = async(token) => {
        await admissionRequestObject.value.getUnfinishedRequest(token).then((response)=>{
          admissionRequestList.value = response.data.response.data.reverse();
        });
    }

    const getColumns = () => {
      if(admissionRequestList.value.length == 0){
        return;
      }

      columns.value = [
        { headerName: "RFC", field: 'rfc'},
        { headerName: "Nombre", field: 'nombre_completo' },
        { headerName: "Teléfono", field: 'telefono' },
        { headerName: "Celular", field: 'celular' },
        { headerName: "Correo", field: 'correo' },
        { headerName: "Fecha de registrado", field: 'fecha_registrado' },
        { headerName: "Proceso", field: 'proceso' },
        { headerName: "Tipo de cliente", field: 'tipo_cliente' },
        { headerName: "Identificacion", field: 'identificacion_oficial', cellRenderer: params => isCheckIcon(params) },
        { headerName: "Comprobante de domicilio", field: 'comprobante_domicilio', cellRenderer: params => isCheckIcon(params) },
        { headerName: "CURP", field: 'curp', cellRenderer: params => isCheckIcon(params) },
        { headerName: "Comprobante de ingreso", field: 'comprobante_ingresos', cellRenderer: params => isCheckIcon(params) },
        { headerName: "Ultimo estado de cuenta", field: 'ultimo_estado_cuenta_bancario', cellRenderer: params => isCheckIcon(params) },
      ];

      columns.value.forEach(element => {
        fields.value.push(element.field);
      });
    }

    const getRows = () => {
      if(admissionRequestList.value.length == 0){
        return;
      }

      rows.value = admissionRequestList.value.filter((admission) => {
          for (let i in admission) {
            if(!fields.value.includes(i)){
              delete admission[i];
            }
          }
          
          return formatFields(admission);
      });
    }

    const isCheckIcon = (params) => {
      const value = params.value == "SI" ? `<img class="mx-auto" src=${checkIcon} />`:`<img class="mx-auto" src=${crossIcon} />`;
      return value;
    }

    const formatFields = (fields) => {
      const statusFields = ['proceso'];
      const lowercaseFields = ['email', 'correo'];
      const dateFields = ['fecha_registrado'];
      const phoneFields = ['celular', 'telefono'];
      const uppercaseFields = ['curp', 'comprobante_domicilio', 'comprobante_ingresos', 'identificacion_oficial', 'ultimo_estado_cuenta_bancario', 'nombre_completo', 'tipo_cliente'];
      
      for(let i in fields){
        if(lowercaseFields.includes(i)){
          fields[i] = fields[i].toString().toLowerCase();
        }else if(uppercaseFields.includes(i)){
          fields[i] = fields[i].toString().toUpperCase();
        }else if(dateFields.includes(i)){
          fields[i] = app.$filters.formatDate(fields[i]);
        }else if(phoneFields.includes(i)){
          fields[i] = app.$filters.formatPhone(fields[i]);
        }else if(statusFields.includes(i)){
          fields[i] = app.$filters.requestStatus(fields[i]);
        }
      }
      return fields;
    }

    return {
      isLoading,
      columns,
      rows,
      render
    };
  },

  components: {
    Loading,
    TableComponent
  }
}
</script>